import React, { Fragment } from 'react';

import ActionIntraPageButton from '../../../common/components/buttons/ActionIntraPageButton';

import global from '../../../common/components/GlobalStyle.module.css';

import { formatDate } from '../../../common/utils/DateFunctions';

const GridRowIcons = ({ person, onSelectMember }) => (
  <Fragment>
    <ActionIntraPageButton type="button" onClick={(e) => onSelectMember(e, person)}>
      Select This Person
    </ActionIntraPageButton>
  </Fragment>
);

const GridRowContent = ({ person }) => (
  <div className={global.SmallTableRowInfo}>
    <div className='row'>
      <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
        Member Name
      </div>
      <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
        {person?.firstName} {person?.middleName} {person?.lastName} {person?.suffix}
      </div>
      <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
        Birth Date
      </div>
      <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
        {formatDate(person.birthDate)}
      </div>
      <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
        Last Membership
      </div>
      <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
        {person?.orgDurations?.find(x => x.organizationName === "USA Swimming")?.orgUnitName || 'Non-USA Swimming Membership'} -
        ({person?.orgDurations?.find(x => x.organizationName === "USA Swimming")?.orgUnitCode || 'Non-USAS'})
      </div>
      <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
        Membership Year
      </div>
      <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
        {person?.orgDurations?.find(x => x.organizationName === "USA Swimming")?.periodName}
      </div>
    </div>
  </div>
);

const GridRow = ({ person, onSelectMember }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <GridRowIcons
            id={person?.personId}
            onSelectMember={onSelectMember}
            person={person} />
        </div>
        <GridRowContent person={person} />
      </div>
    </Fragment>
  );
}
const SmallExistingMemberGrid = ({ state, onSelectMember }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (<div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          &nbsp;
        </div>
        <div className={global.SmallTableRowInfo}>
          {state.isArrayLoading === true
            ? <div className={global.SmallTableRowLabels}>Loading...</div>
            :
            <div className={global.SmallTableRowLabels}>No Results</div>}
        </div>
      </div>)
      : Array.isArray(state?.arrayData) === true
        && state?.arrayData?.length > 0
        ? state?.arrayData?.map((person, i) =>
          <GridRow
            key={i}
            person={person}
            onSelectMember={onSelectMember} />)
        : (<div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No Results</div>
          </div>
        </div>)
    }
  </div>
);

export default SmallExistingMemberGrid;