import { createContext, useState } from 'react';

import AddressTypeData from './AddressTypeData';

export const AddressTypeStateContext = createContext();

const AddressTypeContextProvider = ({ children }) => {
  const stateHook = useState(AddressTypeData.INITIAL_ADDRESS_TYPE_STATE);

  return (
    <AddressTypeStateContext.Provider value={stateHook}>
      {children}
    </AddressTypeStateContext.Provider>
  );
};

export default AddressTypeContextProvider;