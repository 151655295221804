import { useContext } from 'react';

import AddressTypeData from './AddressTypeData';
import { AddressTypeStateContext } from './AddressTypeContextProvider';

import { NO_DATA_MESSAGE } from '../../utils/HttpHelper';

const useAddressTypeData = () => {
  const [addressTypeState, setAddressTypeState] = useContext(AddressTypeStateContext);

  const getAddressTypes = async () => {
    const getAddressTypesResult = await AddressTypeData.getAddressTypesData(addressTypeState, setAddressTypeState);

    if (getAddressTypesResult.arrayData) {
      let newState = {
        ...getAddressTypesResult,
        options: getAddressTypesResult.arrayData.map((x) => { return { id: x.addressTypeId, name: x.typeName } }),
        optionsStandardOnly: getAddressTypesResult.arrayData.filter((x) => x.isNationalTeamOnly !== true).map((x) => { return { id: x.addressTypeId, name: x.typeName } }),
        optionsNationalTeamOnly: getAddressTypesResult.arrayData.filter((x) => x.isNationalTeamOnly === true).map((x) => { return { id: x.addressTypeId, name: x.typeName } }),
        areOptionsLoaded: true
      };

      setAddressTypeState({ ...newState });

      return newState;
    } else {
      throw new Error(NO_DATA_MESSAGE);
    }
  };

  return {
    addressTypeState,
    getAddressTypes
  };
};

export default useAddressTypeData;