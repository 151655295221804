import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import { API_NAMES, initApi } from '../../utils/HttpApiHelper';

const getAddressTypesData = (state, setState) => {
  const api = initApi(API_NAMES.COMMON, state, setState);
  const url = `/AddressType`;
  
  return api.executeArray ? api.executeArray(url, 'GET') : null;
};

const INITIAL_ADDRESS_TYPE_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  optionsStandardOnly: [],
  optionsNationalTeamOnly: [],
  areOptionsLoaded: false
};

const AddressTypeData = {
  INITIAL_ADDRESS_TYPE_STATE,
  getAddressTypesData
};

export default AddressTypeData;